/* eslint-disable react/no-danger */
import React from "react"

import GetConsultationButton from "src/components/GetConsultationButton"
import "./Footer.scss"

const PartnerFooter = () => (
  <div className="partner-footer">
    <div className="container">

      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h3>Ready to get started with Bandwidth?</h3>
          <p>Let’s talk! Vaporware works with business leaders to deliver the right custom software solution to help their company grow and scale. We turn problems into solutions and bring technology ideas to life.</p>
        </div>
      </div>
      <GetConsultationButton />
    </div>
  </div>
)

export default PartnerFooter
