import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import Content from "src/components/Content"
import PartnerFooter from "src/components/partners/Footer"

import "./partnerTemplate.scss"

const PartnerTemplate = ({ data }) => {

  const { page } = data
  const { frontmatter, html } = page
  const { meta, hero } = frontmatter

  return (
  <Layout meta={meta}>
    <div className="partner-template">
      <PageHero {...hero} />
      <article className="partner-body">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Content html={html} />
            </div>
          </div>
        </div>
        <PartnerFooter />
      </article>
    </div>
  </Layout>
  )
}

export default PartnerTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: {meta: {path: {eq: $path}}}) {
      html
      ...MetaFragment
      ...HeroFragment
    }
  }
`
